<template>
	<div class="main-contents prj pf">		
		<div class="tit">완료 프로젝트</div>
		<div class="search-box">
			<!-- <SelectComp type="select" class="wd05" :isAll="true" list="3:공급기업 지정,1:일반 구인" title="프로젝트 구분" v-model="input.projectDivCd"/>						
			<nbsp/> -->
			
			<span v-if="input.projectDivCd == '1'">
				<SelectComp type="select" class="" list="1:프로젝트명,2:프로젝트 담당자명,3:매니저명,4:수행사명" v-model="input.searchGubun"/>						
				<InputComp type="text" :placeholder="input.searchGubun == '1' ? '프로젝트명' : input.searchGubun == '2' ? '프로젝트 담당자명' : input.searchGubun  == '3' ? '매니저명' : '수행사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
			</span>
			<span v-else>
				<SelectComp type="select" class="" list="1:프로젝트명,2:프로젝트 담당자명,3:매니저명,4:수행사명,5:공급사명" v-model="input.searchGubun"/>						
				<InputComp type="text" :placeholder="input.searchGubun == '1' ? '프로젝트명' : input.searchGubun == '2' ? '프로젝트 담당자명' : input.searchGubun  == '3' ? '매니저명' : input.searchGubun  == '4' ? '수행사명' : '공급사명'" v-model="input.searchKeyword" @keyup.enter="getList('1')"  />
			</span>

			<img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getList('1')"/>
		</div>

		<!-- 관련내용없는경우 -->
		<div v-if="pageInfo.totalRecordCount == 0" class="view int_prj none">
			<p>프로젝트가 없습니다!</p>
		</div>

		<div v-for="(project, index) in projectList" :key="project.projectSeq">
			<div class="prj_list-box">
				<div class="no">{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}</div>
				<div class="view int_prj">
					<div class="req">
						<div class="top">
							<div class="proc cp">완 료</div>
						</div>

						<div class="itv-outline int_prj">
							<div class="regi">
								<div v-if="project.projectDivCd == '31' || project.projectDivCd == '32'" class="tit_sco">공급기업지정</div>
								<div v-else class="tit int_prj">프로젝트명</div>
								<div class="name" style="width: 530px;" @click="clickProjectInfo(project.projectSeq)">{{project.projectNm}}</div>
								<div class="sub-new">
									<div class="label ivl04">고객사</div>
									<div class="list" style="display: inline-flex; width: 540px;">
										<div style="white-space: nowrap;">{{project.clientNm}}</div>
										<div class="loca"><img src="/images/location.png" alt="위치"/>{{project.projectLocCont}}</div>
									</div>
								</div>
								<div class="sub-box after">
									<div class="date_new">등록일자 {{project.regYyyymmdd}}</div>
								</div>
							</div>
							<div class="int_box">
								<div class="list-view int_prj">
									<div class="label ivl04">수행사</div>
									<div class="list">
										<span v-if="input.projectDivCd == '1'">{{project.corpNm}}</span>
										<span v-else>{{project.parentCorpNm}}</span>
									</div>
								</div>
								<div v-if="input.projectDivCd == '3'" class="list-view int_prj">
									<div class="label ivl04">공급사</div><div class="list">{{project.corpNm}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl04">담당자</div><div class="list">{{project.corpMgrMberNm}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl05">개발기간</div><div class="list">{{project.projectStartEndYyyymmdd}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl03">개요</div>
									<div class="list text">{{project.projectDtlDesc}}</div>
								</div>
								<div class="list-view int_prj">
									<div class="label ivl05">개발기술</div>
									<div class="list text">{{project.devTechCont}}</div>
								</div>
								<div class="btn-box notice">
									<div class="btn member"><img src="/images/prj_member.png" alt="멤버수"/><p>참여인재<span class="num">{{project.mberCnt}}</span>명</p></div>
									<div class="fold_btn">
										<img v-if="project.mberCnt > 0 && projectSeqTecMber != project.projectSeq" class="fold" src="/images/fold_down_btn.png" alt="버튼" @click="openTecMber(project)"/>
										<img v-if="project.mberCnt > 0 && projectSeqTecMber == project.projectSeq" class="fold" src="/images/fold_top_btn.png" alt="버튼" @click="closeTecMber()"/>
									</div>
									<div v-if="project.unEvalCount > 0" class="alarm show"><img src="/images/admin/no_evl_alarm.png" alt="미평가인재알림"/></div>
								</div>
							</div>
						</div>					
					</div>
					<div class="agent">
						<img src="/images/admin/agent_logo.png" alt="하이프로매니저"/>
						<p>- 하이프로 담당 매니저 -</p>
						<div class="name">
							<span v-if="project.admMgrMberNm" @click="popupAdmMgerMber(project.admMgrMberSeq)">{{project.admMgrMberNm}} &#9654;</span>
							<span v-else>-</span>
						</div>
						<div v-if="project.admLoginId" class="id"><p>id : </p>{{project.admLoginId}}</div>
					</div>
				</div>
			</div>

			<!-- 참여인재 -->
			<div v-if="projectSeqTecMber == project.projectSeq && projectTecMberList.length > 0" 
					class="Board type3 itv show">					   
				<table class="Board_type3 admin">
					<colgroup>
						<!-- <col width="3%">
						<col width="5%">
						<col width="6%">
						<col width="3%">
						<col width="3%">
						<col width="6%">
						<col width="7%">
						<col width="12%">
						<col width="11%">
						<col width="7%">
						<col width="11%"> -->
						<col width="3%">
						<col width="5%">
						<col width="6%">
						<col width="3%">
						<col width="3%">
						<col width="6%">
						<col width="7%">
						<col width="6%">
						<col width="14%">
						<col width="8%">
						<col width="14%">
						<col width="8%">
						<col width="10%">
					</colgroup>
					<thead>
						<tr>
							<th>NO</th>
							<th>평균<br/>평가등급</th>
							<th>성명</th>
							<th>성별</th>
							<th>년생</th>
							<th>기술등급<br/>(학력기준)</th>
							<th>기술등급<br/>(자격증기준)</th>
							<th>투입등급</th>
							<!-- <th>직무분야</th> -->
							<th>참여소속사</th>
							<th>담당분야</th>
							<th>참여기간</th>
							<th>참여결과</th>
							<th>기술인재 평가상태</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(mber, index) in projectTecMberList" :key="mber.mberSeq">
							<td class="score">{{index + 1}}</td>
							<td class="score">
								<grade-img type="TOT_EVAL_ADM" :grade="mber.totEvalGradeCd " :specialYn="mber.specialGradeYn">
									<template v-slot:smark><div class="s-mark"><img src="/images/admin/s-mark.png" alt="스페셜마크"></div></template>
								</grade-img>
							</td>
							<td class="score name" @click="popupMber(mber.mberSeq)">{{mber.mberNm}} &#9654;</td>
							<td class="score">{{mber.gender | gender}}</td>
							<td class="score">{{mber.birthYyyymmdd | birthYear}}</td>
							<td class="score"><grade-img type="SCHOOL_TECH_ADM"  :grade="mber.resumeSchoolTechGradeCd"/></td>
							<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="mber.resumeLicenseTechGradeCd"/></td>
							<!-- 실제 투입등급 -->
							<td class="score"><grade-img type="LICENSE_TECH_ADM" :grade="mber.projectJoinTechGradeCd"/></td>
							
							<!-- <td class="score"><SelectComp type="text_comma_split" cdId="PRO142" :value="mber.dutyCdList"/></td> -->
							<td class="score">{{mber.corpNm}}</td>
							<td class="score">{{mber.workCont}}</td>
							<td class="score">{{mber.joinStartYyyymmdd | date('yyyy.MM.dd')}} - {{mber.joinEndYyyymmdd | date('yyyy.MM.dd')}}</td>
							<td class="score">
								<!--									
								<div class="state pf"></div>참 여 중
								<div class="state cp"></div>참여완료
								<div class="state stop"></div>중도철수
								-->
								<div :class="mber.projectRecrStatus == '1' ? 'state pf' : mber.projectRecrStatus == '0' ? 'state stop' : 'state cp'"/>
								<SelectComp type="text" list="0:중도철수,1:참 여 중,2:참여예정,9:참여완료" :value="mber.projectRecrStatus"/>

							</td>
							<td v-if="(mber.projectRecrStatus == 9 || mber.projectRecrStatus == 0) && mber.evalFinishYn == 'Y'" class="score resume">
								<div class="btn" @click="viewEval(project, mber.mberSeq)">평가보기 &#9654;</div>
							</td>
							<td v-else-if="(mber.projectRecrStatus == 9 || mber.projectRecrStatus == 0) && mber.evalFinishYn != 'Y'" class="score resume rq">
								<div class="btn" @click="reqEval(project, mber.mberSeq)">평가요청 &#9654;</div>
							</td>
							<td v-else class="score">
								<div class="btn">-</div>
							</td>
						</tr>						
					</tbody>
				</table>
			</div>
		</div>

		<!-- 페이징부분 -->
		<paging-comp :pageInfo="pageInfo" @page-click="goPage"/>
	</div><!-- //main-contents -->
</template>
<script>
import pagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";

export default {
	components: { pagingComp, gradeImg },
	data() {
		return {
			input : {
				projectDivCd : '',
				searchGubun : '1',		// 1:프로젝트명, 2:담당자명, 3:매니저명, 4:수요기업명
				projectStatus : ['9'],	// 1:수행중, 2:수행예정, 9:완료
				searchKeyword: '',
			},
			
			nowYyyymmdd : '',

			projectList : {},
			pageInfo : {},

			projectTecMberList : [],
			projectSeqTecMber : '',
		};
	},
	
	beforeMount() {
		if(this.$route.name == 'PRJ913M01'){
            this.input.projectDivCd = '1';
        }else{
            this.input.projectDivCd = '3';
			this.input.searchKeyword = this.$route.query.projectNm;
        }
	},

	mounted(){

		this.nowYyyymmdd = new Date().format("yyyyMMdd");

		var keys = Object.keys(this.input);
		for(var key in this.$route.params){
			if(keys.indexOf(key) >= 0){
				this.input[key] = this.$route.params[key];
			}
		}

		this.getList();
	},
	methods: {
        getList(div, isScroll = false){

			if(div) this.input.pageIndex = 1;
			
            this.$.httpPost('/api/prj/adm/getProjectList', this.input)
                .then(res => {
                    this.projectList = res.data.list;
                    this.pageInfo = res.data.pageInfo;

					this.projectSeqTecMber = '';

					if(this.projectList.length > 0 && this.projectList[0].mberCnt > 0) {
						// 참여인재 조회
						this.projectList[0].isOpenTecMber = true;
						this.projectSeqTecMber = this.projectList[0].projectSeq;
						this.getTecMberList(this.projectSeqTecMber);
					}

                    if(isScroll) {
						window.scroll(0, 0);
					}
				})
                .catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
        }, 
		
		getTecMberList(projectSeq) {

			var input = {
				projectSeq : projectSeq
			};

			this.$.httpPost('/api/prj/adm/getProjectTecMberList', input)
				.then(res => {
					// console.log(res.data);
					this.projectTecMberList	= res.data.list;

					this.projectSeqTecMber = projectSeq;

				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},	
		
		openTecMber(project) {
			this.getTecMberList(project.projectSeq);
		},

		closeTecMber() {	
			this.projectSeqTecMber = '';		
			// this.$forceUpdate();
		},		
		
		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList();
		},

		clickProjectInfo(projectSeq) {

			var viewId = 'PRJ911M03'
			if(this.input.projectDivCd == '3') viewId = 'PRJ921M03'

			var params = {
				caller : {
					name: this.$route.name,
					params: this.input
				}
			}

			this.$router.push({name: viewId, query : {projectSeq : projectSeq}, params });

		},	

		popupMber(mberSeq) {
			var param = {};
			param.reqMberSeq = mberSeq;
			param.div = 'tec'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},		

		// 프로젝트 담당 매니저 정보 보기
		popupAdmMgerMber(admMgrMberSeq) {
			var param = {};
			param.reqMberSeq = admMgrMberSeq;
			param.div = 'agt'; // tec - 개인, gmg - 관리총괄, pmg - 프로젝트 담당자, sco - 공급기업, agt - 매니저

			this.$.popup('/adm/mem/MEM923P02', param);
		},

		// 참여기간 변경
		changeJoinStartEndYyyymmdd(projectSeq, tecMberSeq) {
			//alert('프로젝트 참여기간 변경 개발 해야 함 projectSeq : ' + projectSeq + ', mberSeq : ' + mberSeq);
			this.$.popup('/adm/prj/PRJ912P01', {projectSeq, tecMberSeq})
				.then(res => {
					if(res){
						this.getTecMberList(projectSeq);
					}
				});
		},

		// 평가보기
		viewEval(project, tecMberSeq) {
			var projectSeq = project.projectSeq;
			//alert('평가보기 개발 해야 함 projectSeq : ' + projectSeq + ', mberSeq : ' + mberSeq);
			this.$.popup('/adm/prj/PRJ912P03', {projectSeq, tecMberSeq});
		},

		// 평가요청
		reqEval(project, tecMberSeq) {
			if(!this.editable(project)) return alert('총괄관리자 또는 담당매니저만 평가요청이 가능합니다.');
			var projectSeq = project.projectSeq;
			//alert('평가요청 개발 해야 함 projectSeq : ' + projectSeq + ', mberSeq : ' + tecMberSeq);
			this.$.popup('/adm/mem/MEM912P03', {projectSeq, tecMberSeq});
		},

		editable(project){
			return this.$store.state.userInfo.mberDivCd == '00'				//총괄관리자
				|| this.$store.state.userInfo.mberSeq == project.admMgrMberSeq;//담당매니저
		}
		
	},
};
</script>
